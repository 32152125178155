import React from 'react'
import Navbar from '../Components/Common/Navbar/Navbar'
import HeroSection from '../Components/HomePage/Herosection/HeroSection'
import ProductionList from '../Components/HomePage/ProductionList/ProductionList'
import HowWeMake from '../Components/HomePage/HowWeMake/HowWeMake'
import ContactForm from '../Components/HomePage/ContactForm/ContactForm'
import Footer from '../Components/HomePage/Footer/Footer'
import HWMCard from '../Components/HomePage/HWMCard/HWMCard'

function Home() {
  return (
    <div className='Home'>
        <Navbar/>
        <HeroSection/>
        <HowWeMake/>
        <ProductionList/>
        <ContactForm/>
        {/* <Footer/> */}
    </div>
  )
}

export default Home