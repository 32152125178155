import React, { useEffect, useState } from 'react';
import '../../../styles/ScrollUp.css'

function ScrollUp() {

    const [scrollup, setScrollup] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 1500) {
                setScrollup(true);
            } else {
                setScrollup(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
        <div className={scrollup ? 'ScrollUp active' : 'ScrollUp'}onClick={scrollToTop}>
            <div className="icon">
                <i class="fa-solid fa-chevron-up"></i>
            </div>
        </div>
    )
}

export default ScrollUp