import React, { useRef, useState } from 'react';
import '../../../styles/ContactForm.css';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from "react-google-recaptcha";


function ContactForm() {

    const form = useRef();
    const [verified, setVerified] = useState(false);

    function onChange(value) {
        console.log("Captcha value:", value);
        setVerified(true);
    }
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });



    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_n5jyjdm', 'template_yjv034a', form.current, 'LnD1ohDHZ3Oz2O9QQ')
            .then((result) => {
                console.log(result.text);
                console.log("message sent");
                toast.success('Message Sent! Your message has been sent successfully.');
                // Reset the form fields after successful submission
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    message: ''
                });
            }, (error) => {
                console.log(error.text);
                toast.error('An error occurred. Please try again!');
            });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    return (
        <div className='ContactForm' id='contact'>
            <div className="container">
                <h2 data-aos="fade" data-aos-duration="1000">Get In Touch</h2>
                <div className='row'>
                    <div className='card'>
                        <div className='col left' data-aos="fade" data-aos-duration="1000">
                            <form ref={form} onSubmit={sendEmail}>
                                <div>
                                    <input type='text' id='name' name='name' value={formData.name} onChange={handleInputChange} placeholder='Name' required title="Enter your full name(maximum 30 characters)" maxLength={30} />
                                </div>
                                <div>
                                    <input type='email' id='email' name='email' value={formData.email} onChange={handleInputChange} placeholder='Email' required />
                                </div>
                                <div>
                                    <input type='text' id='phone' name='phone' value={formData.phone} onChange={handleInputChange} placeholder='Phone' required pattern="[0-9]{10}" title="Please enter a valid 10-digit mobile number" />
                                </div>
                                <div>
                                    <textarea id='message' name='message' value={formData.message} onChange={handleInputChange} placeholder='Message' rows={4} maxLength={500} title="Enter your message (maximum 500 characters)" required />
                                </div>
                                <ReCAPTCHA
                                    sitekey="6Lf4VfEpAAAAAKdCWbDF_MbajRX7-7QyHHb9NDWT"
                                    onChange={onChange}
                                />
                                <button type='submit' disabled={!verified}>Submit</button>


                            </form>
                        </div>
                        <div className='col right' data-aos="fade" data-aos-duration="1000">
                            <ul>
                                <li><span><i class="fa-solid fa-location-dot"></i></span>
                                    <a href="https://maps.google.com/maps?ll=11.273192,75.787334&z=14&t=m&hl=en&gl=IN&mapclient=embed&cid=7229980631697168962"> 5/639R 1st Floor, City Gate Building
                                        Mini Bypass Rd, Eranhippalam,
                                        Kozhikode, kerala</a></li>
                                <li><span><i class="fa-regular fa-envelope"></i></span><a href='mailto:info@hawksee.in'>info@hawksee.in</a></li>
                                <li><span><i class="fa-solid fa-phone-volume"></i></span><a href='tel:+919995000123'>+91-9995000123</a></li>
                                <li><span><i class="fa-solid fa-phone-volume"></i></span><a href='tel:+919995422122'>+91-9995422122</a></li>

                            </ul>

                            <div className="social-media-icons">
                                <h3>Connect us via:</h3>
                                <a href="https://www.facebook.com/hawkseedigital/" target="_blank" rel="noopener noreferrer">
                                    <i class="fa-brands fa-facebook"></i>

                                </a>
                                <a href="https://www.instagram.com/hawkseedigital/?hl=en" target="_blank"
                                    rel="noopener noreferrer">
                                    <i class="fa-brands fa-instagram"></i>
                                </a>
                                <a href="https://wa.me/+919995000123" target="_blank"
                                    rel="noopener noreferrer">
                                    <i class="fa-brands fa-whatsapp"></i>
                                </a>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <div className="copyright">
                <p>© Copyright - Hawkspot</p>
            </div>
        </div>
    )
}

export default ContactForm