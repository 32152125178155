import React from 'react';
import '../../../styles/HeroSection.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../../../styles/HeroSection.css'


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';


// import required modules
import { Autoplay, EffectFade, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';


function HeroSection() {
  return (
    <div className='HeroSection'>
        <Swiper

          direction={'horizontal'}
          spaceBetween={0}
          centeredSlides={true}
          effect={'fade'}

          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 4500,
            disableOnInteraction: true,
          }}
          modules={[Autoplay, EffectFade, Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
                    <div className="img-container">
                        <img src="https://images.unsplash.com/photo-1543242594-c8bae8b9e708?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />
                    </div>
                       
                </SwiperSlide>
          <SwiperSlide>
                    <div className="img-container">
                        <img src="https://images.unsplash.com/photo-1607112812619-182cb1c7bb61?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />
                    </div>
                       
                </SwiperSlide>
          <SwiperSlide>
                    <div className="img-container">
                        <img src="https://images.unsplash.com/photo-1596483941348-cb6d15e0fd60?q=80&w=1471&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />
                    </div>
                       
                </SwiperSlide>
        </Swiper>
          <div className="content">
            <h1>Crafting Your Brand's Story Through Engaging Content</h1>
            <p>At Hawkspot, we specialize in creating compelling content that connects, engages, inspires, and captivates the audience.<br></br>
            <span>#getspotted</span>
            </p>
            <Link to={'#contact'}>Get In Touch
            
            </Link>
          </div>

    </div>
  )
}

export default HeroSection