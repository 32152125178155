import React, { useEffect, useRef, useState } from 'react';
import '../../../styles/HowWeMake.css';
import CountUp from 'react-countup';
import HWMCard from '../HWMCard/HWMCard';
import StepCards from '../StepCards/StepCards';


function HowWeMake() {

    const [isVisible, setIsVisible] = useState(false);
    const counterRef = useRef();

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '-100px',
            threeshold: 0.5,
        };
        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            })
        }

        const observer = new IntersectionObserver(handleIntersection, options);
        observer.observe(counterRef.current);

        return () => {
            observer.disconnect();
        }
    }, []);
    return (
        <div className='HowWeMake'>
            <div className="container">
                <div className="counter-section" data-aos="fade" data-aos-duration="1000" data-aos-offset="0" ref={counterRef}>
                    <div className="col">
                        <div className="icon">
                            <img src="img/video.png" alt="videos" />
                        </div>
                        <div className="content">
                            {isVisible && <CountUp end={'100'} duration={3} />}<span>+</span>
                            <h3>Videos</h3>
                        </div>
                    </div>
                    <div className="col">
                        <div className="icon">
                            <img src="img/brand.png" alt="videos" />

                        </div>
                        <div className="content">
                            {isVisible && <CountUp end={'30'} duration={3} />}<span>+</span>
                            <h3>Brands</h3>
                        </div>
                    </div>
                    <div className="col">
                        <div className="icon">
                            <img src="img/group-chat.png" alt="videos" />

                        </div>
                        <div className="content">
                            {isVisible && <CountUp end={'10'} duration={3} />}<span>+</span>

                            <h3>Members</h3>
                        </div>
                    </div>

                </div>
                <div className="heading-section" data-aos="fade" data-aos-duration="1000">
                    <h2>How We Shine a Spotlight on Your Content</h2>
                    <p>At Hawkspot, we transform your ideas into compelling visual stories that capture attention and engage your audience. Our expert production team specializes in every aspect of content creation to ensure your message is delivered with precision and creativity.
                    </p>
                </div>
                {/* <HWMCard/> */}
                <StepCards/>
                

            </div>
        </div>
    )
}

export default HowWeMake