import React, { useState } from 'react';
import '../../../styles/StepCards.css';

function StepCards() {
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Ideation', 'Planning', 'Scripting', 'Execution'];

    const handleStepClick = (index) => {
        setActiveStep(index);
    };

    // Data array
    const stepContent = [
        { title: 'Ideation', text: "We're a team of creative thinkers who thrive on ideation. We collaborate with you to uncover the essence of your brand, crafting a narrative that resonates with your audience. Our concept development process refines your idea into a clear and compelling concept, laying the groundwork for a successful project.", img:"img/how-we-shine/pikaso_texttoimage_ideation-in-digital-marketing-three-peoples-discus.jpeg" },
        { title: 'Planning', text: "Our planning expertise ensures a seamless pre-production process. We handle storyboarding, scheduling, and budgeting, visualizing your vision and bringing it to life. With meticulous attention to detail, we ensure every aspect is perfectly aligned with your goals.", img:"img/how-we-shine/pikaso_texttoimage_planning-in-video-production-illustration-style.jpeg" },
        { title: 'Scripting', text: "Compelling scriptwriting is our forte. We craft engaging narratives that capture your brand's voice and message, refining the script to ensure a polished final product. Our experienced writers bring creativity and precision to every project, ensuring your story resonates with your audience.", img:"img/how-we-shine/pikaso_texttoimage_scripting-in-digital-marketing-illustration-style-.jpeg" },
        { title: 'Execution', text: "With state-of-the-art equipment and expertise, we deliver high-quality video production. Our post-production team perfects every detail, from editing to visual effects and sound design, ensuring a flawless final product.", img:"img/how-we-shine/pikaso_texttoimage_video-shooting-illustration-style.jpeg" },
    ];

    return (
        <div className='StepCards'>
            <div className="progress-bar-container"data-aos="fade"data-aos-duration="1000">
                <div className={`progress-bar step-${activeStep}`}>
                    <div className="progress"></div>
                    {steps.map((step, index) => (
                        <div key={index} className={`step ${index <= activeStep ? 'active' : ''}`}>
                            <div className="step-number"
                                onClick={() => handleStepClick(index)}
                            >{index + 1}</div>
                            <button
                                className={`step-button ${index === activeStep ? 'current' : ''}`}
                                onClick={() => handleStepClick(index)}
                            >
                                {step}
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            <div className="description-box"data-aos="fade"data-aos-duration="1000">
                <h3 className="box-title">{stepContent[activeStep].title}</h3>
                <img src={stepContent[activeStep].img} alt="steps" />
                {/* <img src="img/how-we-shine/pikaso_texttoimage_ideation-in-digital-marketing-three-peoples-discus.jpeg" alt="" /> */}
                <p className="box-text">{stepContent[activeStep].text}</p>
            </div>
        </div>
    );
}

export default StepCards;
