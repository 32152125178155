import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import ScrollToAnchor from './Components/ScrollAnchor/ScrollAnchor';
import Whatsapp from './Components/HomePage/Whatsapp_btn/Whatsapp';
import ScrollUp from './Components/HomePage/ScrollUp/ScrollUp';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Whatsapp/>
      <ScrollToAnchor/>
      <ScrollUp/>
        <Routes>

          <Route path='/' element={<Home />} />
          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
