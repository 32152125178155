import { createContext, useState } from "react";

const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
    const [image, setImage] = useState('../img/hawkspot.png');

    return (
        <MenuContext.Provider value={{ image, setImage }}>
            {children}
        </MenuContext.Provider>
    );

}
export default MenuContext;