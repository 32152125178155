import React, { useContext, useEffect, useState } from 'react';
import '../../../styles/Navbar.css';
import { Link, useLocation } from 'react-router-dom';
import MenuContext, { MenuProvider } from '../../../Context/menuProvider';

function Navbar() {
  const { handleToggleMenu, navbar, setImage, overlay, image, setOverlay } = useContext(MenuContext);
  const location = useLocation(); // Get the current location




  const shouldReload = (path) => {
    // Check if the clicked link matches the current location
    return location.pathname === path;
  };

  return (
    <div>
      <div className='Navbar'>
        <header className={navbar ? 'header active' : 'header'}>
          <div className="logo">
            <Link to="/"><img src={image} alt="Hawksee logo" /></Link>
          </div>

          <div className="social-media-icons">
            <ul>
              <li><a href="https://www.instagram.com/hawkseedigital/?hl=en"target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-instagram instagram"></i></a></li>
              {/* <li><a href="#"><i class="fa-brands fa-whatsapp whatsapp"></i></a></li> */}
              <li><a href="https://www.facebook.com/hawkseedigital/"target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-square-facebook facebook"></i></a></li>
            </ul>
          </div>

        </header>
      </div>
    </div>
  );
}

export default Navbar;
