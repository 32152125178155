import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import '../../../styles/ProductionList.css'
import { Link } from 'react-router-dom'
import { wordpressUrl } from '../../../Helpers/wordpressUrl';



function ProductionList() {
  const [productions, setProductions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchProductionsIds, setFetchedProductionsIds] = useState(new Set());
  const [page, setPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [filteredArray, setFilteredArray] = useState([]);
  const perPage = 100;
  const lastPageIndex = page * perPage;

  const [activeVideoId, setActiveVideoId] = useState(null);


  // fetch productions function 

  const fetchProductions = () => {

    axios.get(`${wordpressUrl}wp-json/wp/v2/productions?per_page=${perPage}&page=${page}`)
      .then((res) => {
        const newProductions = res.data.filter((production) => !fetchProductionsIds.has(production.id));
        setProductions((prevProductions) => [...prevProductions, ...newProductions]);
        setFilteredArray((prevProductions) => [...prevProductions, ...newProductions]);
        newProductions.forEach((production) => fetchProductionsIds.add(production.id));
        console.log(filteredArray)
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);


      })
  }

  useEffect(() => {
    fetchProductions();
  }, [page, selectedCategory])



  // Shuffle array function using Fisher-Yates algorithm
  const shuffleArray = (array) => {
    let shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };
  // filter by category function 

  const filterByCategory = (category) => {
    if (category === 'All') {
      setFilteredArray(productions);
    } else {
      const filtered = productions.filter(production => production.acf.category === category);
      setFilteredArray(filtered);
    }
    setSelectedCategory(category);
  };

  // load more contents function 
  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  // view button toggle active class function 

  const handleViewVideo = (id) => {
    setActiveVideoId(id);
  }


  if (loading) {
    return (
      <div className="spinning_loading">
        <div class="loader"></div>
      </div>
    );
  }
  return (
    <div className='ProductionList'>
      <div className="container">
        <h2 data-aos="fade" data-aos-duration="1000">Our Works</h2>
        <div className="category-buttons" data-aos="fade" data-aos-duration="1000">
          <button onClick={() => filterByCategory('All')} className={selectedCategory === 'All' ? 'active' : 'not-active'}>All</button>
          <button onClick={() => filterByCategory('Concept Oriented')} className={selectedCategory === 'Concept Oriented' ? 'active' : 'not-active'}>Concept Oriented</button>
          <button onClick={() => filterByCategory('Campaign')} className={selectedCategory === 'Campaign' ? 'active' : 'not-active'}>Campaign</button>
          <button onClick={() => filterByCategory('Explainer Videos')} className={selectedCategory === 'Explainer Videos' ? 'active' : 'not-active'}>Explainer Videos</button>
          <button onClick={() => filterByCategory('Product Showcasing')} className={selectedCategory === 'Product Showcasing' ? 'active' : 'not-active'}>Product Showcasing</button>
          <button onClick={() => filterByCategory('User generated content')} className={selectedCategory === 'User generated content' ? 'active' : 'not-active'}>User generated content</button>
          <button onClick={() => filterByCategory('Influencer Collabs')} className={selectedCategory === 'Influencer Collabs' ? 'active' : 'not-active'}>Influencer Collabs</button>
          <button onClick={() => filterByCategory('Testimonials')} className={selectedCategory === 'Testimonials' ? 'active' : 'not-active'}>Testimonials</button>
          <button onClick={() => filterByCategory('Restaurant shoots')} className={selectedCategory === 'Restaurant shoots' ? 'active' : 'not-active'}>Restaurant shoots</button>
        </div>

        <div className="category-dropdown" data-aos="fade" data-aos-duration="1000">
          <select
            value={selectedCategory}
            onChange={(e) => filterByCategory(e.target.value)}
            className="category-select"
          >
            <option value="All">All</option>
            <option value="Concept Oriented">Concept Oriented</option>
            <option value="Explainer Videos">Explainer Videos</option>
            <option value="Product Showcasing">Product Showcasing</option>
            <option value="User generated content">User generated content</option>
            <option value="Influencer Collabs">Influencer Collabs</option>
            <option value="Testimonials">Testimonials</option>
            <option value="Restaurant shoots">Restaurant shoots</option>
          </select>
        </div>
        <div className="row">
          {filteredArray.length === 0 ? (
            <div className='no-productions-message'>
              <h3>No Works in this category.</h3>
            </div>
          ) : (
            shuffleArray(filteredArray).map(production => (
              <div className="card" key={production.id} data-aos="fade" data-aos-duration="1000">
                <iframe src={production.acf.video_link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;" loading="lazy" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                {/* <div className="content">
                  {production.acf.description && <p>{production.acf.description}</p>}
                </div> */}
                <div className={`content-container ${activeVideoId === production.id ? 'active' : ''}`} style={{ backgroundImage: `url(${production.acf.thumbnail.sizes.medium_large})` }}>
                  <div className="content">
                    <p>{production.acf.description}</p>

                    {production.acf.video_link ? (
                      <a onClick={() => handleViewVideo(production.id)} target="_blank" rel="noopener noreferrer" class="arrow-container animated fadeInDown">
                        <div class="arrow-2">
                          <i class="fa-solid fa-play"></i>
                        </div>
                        <div class="arrow-1 animated hinge infinite zoomIn"></div>
                      </a>

                    ) : (
                      // production.acf.other_links && <a href={production.acf.other_links} target="_blank" rel="noopener noreferrer">Click here to View Video</a>
                      production.acf.other_links &&
                      <a href={production.acf.other_links} target="_blank" rel="noopener noreferrer" class="arrow-container animated fadeInDown">
                        <div class="arrow-2">
                          <i class="fa-solid fa-play"></i>
                        </div>
                        <div class="arrow-1 animated hinge infinite zoomIn"></div>
                      </a>
                    )}

                  </div>
                </div>
              </div>

            ))
          )}
        </div>
        <div className="load_more">



          {/* {filteredArray.length >= lastPageIndex && <button className='loadMore_btn' onClick={handleLoadMore}>Load More</button>} */}
          {filteredArray.length >= lastPageIndex - 1 && <button className='loadMore_btn' onClick={handleLoadMore}>Load More</button>}


        </div>
      </div>

    </div >
  )
}

export default ProductionList